
const baseUrl = "https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/";
// const baseUrl = "https://eggqh64ytc.execute-api.ap-south-1.amazonaws.com/dev3/";
const devV1 = baseUrl+"api/v1";
const devV2 = baseUrl+"api/v2";
const devV3 = baseUrl+"api/v3";

const TRADESTOREDEV = 'TjoLRuYDJnDlmXt';
const TRADESTORELIVE = 'yWpACASysHLXvWJ';

var devconfig = {
	v1base: devV1,
	v2base: devV2,
	v3base: devV3,
	v1: devV1 + "/user",
	v2: devV2 + "/user",
	v3: devV3 + "/user",
	partnerV1: devV1 + "/partner",
	
	aliceBlueBaseURL: "https://ant.aliceblueonline.com/",
	aliceBlueAuthURL: "https://ant.aliceblueonline.com/",
	clientID: "TRADESTORELIVE",
	redirectURI: "https://tradestore.aliceblueonline.com/callback",
	appCode: TRADESTOREDEV, //"yoz9xT3JQL5NEpU",
	googleClientId: "650602951430-nq0e8l9a95lqe4k74rlkqii06vvq526i.apps.googleusercontent.com",
}

export default devconfig;

// TRADESTOREDEV	jyE3tevUPJDcWyl	3TfW1591mkY4Z5OqBqyeSOKZlKiBsxD9qIkWmu9Q75LzXjO0D7WyHOtQ1lbFHvOYHrctZcfqPnhlQ7B3DM9xny9OflCF46VdxUQZ	NA	https://evoque-tradestore-v3.netlify.app/callback
// TRADESTORELIVE	yoz9xT3JQL5NEpU	J4LIX7tLLqc3tg3VOy0Y14JJIdSiKS7zRO2eWqDfFJVJLd4VqV3zVBpoj4ZMUbpFlgIB7PTcQ5Ri7EeXxqYsJgUgmD8nCemdJsb0	NA	https://tradestore.aliceblueonline.com/callback


// aliceBlueBaseURL: "https://ant.aliceblueonline.com/",
// aliceBlueAuthURL: "https://ant.aliceblueonline.com/oauth2/",
// clientID: "TRADESTOREDEV",
// redirectURI: "https://evoque-tradestore-v3.netlify.app/callback",
// responseType: "code",
// state: "dev_state",
// authString: "Basic VFJBREVTVE9SRTpXQTMzcnZQTDhabEdXeWtHZUk3OThYUWhRV2JQUllwWlRYOFg0Y1h6QnBIOHFrbzFPOXVSV1BpbXdnY1dvTldU",
// googleClientId: "650602951430-nq0e8l9a95lqe4k74rlkqii06vvq526i.apps.googleusercontent.com",

// live3
	// aliceBlueBaseURL: "https://ant.aliceblueonline.com/",
	// aliceBlueAuthURL: "https://ant.aliceblueonline.com/oauth2/",
	// clientID: "TRADESTORE",
	// redirectURI: "https://tradestore.aliceblueonline.com/callback",
	// responseType: "code",
	// state: "dev_state",
	// authString: "Basic VFJBREVTVE9SRTpXQTMzcnZQTDhabEdXeWtHZUk3OThYUWhRV2JQUllwWlRYOFg0Y1h6QnBIOHFrbzFPOXVSV1BpbXdnY1dvTldU",
	// googleClientId: "650602951430-nq0e8l9a95lqe4k74rlkqii06vvq526i.apps.googleusercontent.com",