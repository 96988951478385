import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
/*Import Services*/
import APIPost from '../services/apiCall/post';
import APIGet from '../services/apiCall/get';
import devconfig from '../config/config.js';

// import components
import Slider from '../components/slider/slider';
import CardSlider from '../components/slider/CardSlider';
import Form from '../components/form/form';
import Playstore from '../components/banners/playstoreBanner';
import JoinAliceblue from '../components/banners/joinAliceblue';
import PrimaryBlueButton from '../components/buttons/primaryBlueButton';
import PrimaryOutlineButton from '../components/buttons/primaryOutlineButton';
import VideoModal from '../components/modal/VideoModal';

import ProductCard from '../components/cards/productCard';
import PopUp from '../components/popup/popUpNotification';

// import asstes
import arrowdown from '../assets/images/home/Vector .png';
import playbtn from '../assets/images/home/playbtn-white.png';
import multipletool from '../assets/images/home/multiple-tool.png';
import custometrade from '../assets/images/home/custome-trade.png';
import algobased from '../assets/images/home/algo-based.png';
import discount from '../assets/images/home/special-disc.png';
import cardcolbg from '../assets/images/home/cardcolbg.svg';
import fiftypluscolbg from '../assets/images/home/fiftypluscolbg.svg';
import sensibull from '../assets/images/home/sensibull.png';
import smallcase from '../assets/images/home/smallcase.png';
import smalcasecardbg from '../assets/images/home/smalcasecardbg.svg';
import tickertape from '../assets/images/home/tickertape.png';
import alicebluelogo from '../assets/images/home/alicebluelogo.png';
import visitArrow from '../assets/images/home/visitArrow.svg';
import hometradesbg from '../assets/images/home/hometradesbg.png';
import hometradesbg1 from '../assets/images/home/hometradesbg1.png';
import RevenueBro from '../assets/images/home/Revenue-bro.svg';
import SavingsBro from '../assets/images/home/Savings-bro.svg';
import DarkBro from '../assets/images/home/Dark-analytics-bro.svg';
import cardarrow from '../assets/images/home/cardarrow.svg';
import sensigroup from '../assets/images/marketplaceDetails/sensigrop.svg';
import Mibots from '../assets/images/marketplaceDetails/mibots.png';
import Neotrader from '../assets/images/marketplaceDetails/neotrader.png';
import TrueData from '../assets/images/marketplaceDetails/truedata.png';
import closebtn from '../assets/images/header/close.png';
import Smallcase from '../assets/images/marketplaceDetails/smallcase.png';
import Lyoptions from '../assets/images/marketplaceDetails/options.png';
import { Modal } from 'bootstrap';
import ConfirmAgreement from '../components/modal/confirmAgreement';

function showLogin() {
  var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  $("body").append(overlaylogin);
  $('.e-login').addClass('e-login-fly');

}

export default class home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      products: [],
      play: true,
      isModalOpen: false,
    }
  }

  componentDidMount() {
    localStorage.setItem('user_already_logged', true)
    this.getProducts();
    this.getPopUp();
    if (!localStorage.getItem("access_token")) {
      if (!localStorage.getItem("agreed")) {
        var modal = new Modal(document.getElementById("e-confirm-purchase"))
        modal.show()
      }
    }
  }

  pauseVideo = () => {
    this.setState({ play: false });
  }
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
    var overlaylogin = '<div class="e-modal-overlay" id="e-modal-overlay"></div>';
    $("body").append(overlaylogin);
    document.getElementById('e-modal-overlay').addEventListener('click', this.removeOverlay);
  }
  removeOverlay = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
    $('#e-modal-overlay').remove();
  }


  getProducts() {
    var url = devconfig.v3 + "/home/products";

    APIGet(url).then(function (response) {
      console.log(response);
      if (response.status === "ok") {
        this.setState({ products: response.data });
      }
    }.bind(this))
  }

  getPopUp() {
    var url = devconfig.v3 + "/notification/get-banner";

    APIGet(url).then(function (response) {
      console.log(response);
      if (response.status === "ok") {
        // if(localStorage.getItem('popup_id') == response.data.id && localStorage.getItem('popup_close')){
        // 	// this.setState({close: true});
        // }
        if (localStorage.getItem('popup_id') != response.data.id) {
          localStorage.setItem('popup_id', response.data.id);
          localStorage.setItem('popup_close', false)
        }
        this.setState({ notification: response.data });

      }
    }.bind(this))
  }
  closePopUp = () => {
    this.setState({ close: true });
    localStorage.setItem('popup_close', true);
  }



  accountOpen() {
    $(".e-signup-btn").trigger("click");
  }


  render() {
    return (
      <React.Fragment>
        <section className="e-home-slider-sec position-relative">
          <div className="container e-home">
            <div className="row ">
              <div className="col-lg-7 col-md-8 col-sm-12 offset-lg-0 offset-md-2 text-left">
                {/*<h5 className="e-home-h5"><span className="e-home-head">Tradestore -</span> <span className="e-home-subhead">Define. Pick. Trade</span></h5>*/}
                <h5 className="e-home-h5"><span>Tradestore - </span>Define. Pick. Trade</h5>
                <Slider />
              </div>
              {/* <div className="col-lg-5 col-md-8 offset-md-2 offset-lg-1 text-left e-form-col">
                <Form className="e-home-form" type="1" />
              </div> */}
            </div>
            <div className="row">
              <div className="col-lg-12 text-right">
                <img src={arrowdown} alt="down arrow" className="mt-4 e-home-downarrow " />
              </div>
            </div>
          </div>
        </section>
        <section className="e-home-prdt-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <img src={cardcolbg} alt="bg" className="e-prdt-col-bg" />
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="card border-0 text-center e-home-product-card">
                      <img src={Lyoptions} alt="1Lyoptions logo" className="  e-home-trades-img" style={{ borderRadius: "5px" }} />
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="card border-0 text-center e-home-product-card" style={{ padding: "40px" }}>
                     
                      <img src={Neotrader} alt="sensibull logo" className="  e-home-trades-img" />
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="mt-4 card border-0 text-center e-home-product-card p-4" >
                      {/* <img src={Neotrader} alt="sensibull logo" /> */}
                      <img src="https://www.logosvgpng.com/wp-content/uploads/2020/12/smallcase-technologies-private-limited-logo-vector.png" alt="Mutual fund logo" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <img src={fiftypluscolbg} alt="bg img" className="e-fiftypluscol-bg" />
                    <div className="card border-0 mt-5 e-home-product-card-sm ">
                      <span className="e-hm-card-span">50<sub>+</sub></span>
                      <h6 className="e-hm-card-h6 mt-5">Trading Products</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 offset-lg-1 mt-5 col-md-12 col-sm-12 e-home-trademrkt-col">
                <div className="row mt-5">
                  <div className="col-lg-12">
                    <hr className="e-form-rule" />
                    <h5 className="e-home-head mt-4">Marketplace of Web & App based trading products for Traders, Investors, and Strategists.</h5>
                    <p className="mt-4 mb-4 e-home-service-p">We have curated a wide range of web & app based trading platforms customized for your trading needs. Explore the products to make your Trading journey smoother. Our customers can access the Trade Store with their Aliceblue Login credentials.</p>
                    {/* <PrimaryBlueButton name="Open new account" handleClick={this.accountOpen.bind(this)} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="e-home-serive-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <hr className="e-form-rule" />
                <h5 className="e-home-head mt-3">Why Trade Store?</h5>
                <p className="mt-4 e-home-service-p">You get a range of Apps for Goal based investing when you open an account with us.You can try before you buy with Free trials & offers!</p>
                {/* <button className="e-home-vedio-button mt-3" data-toggle="modal" data-target="#homevideo" onClick={() => this.setState({ isModalOpen: true })}>Watch video<img src={playbtn} className="ml-2" /></button> */}
                <div className='mt-4'>
                  <a href="#" style={{ pointerEvents: "none" }}><b>Note: </b>Please note that we have no partnerships with any algo trading platforms.</a>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 ">
                <div className="row">
                  <div className="col-lg-6 col-md-6 ">
                    <div className="media e-algo-card e-media-card p-2">
                      <div className="media-body e-blue-card">
                        <h6 className="e-home-media-head p-2">Multiple Trading Tools</h6>
                      </div>
                      <img src={multipletool} alt="image" className="e-why-trade-card-img-blue" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6  mt-4">
                    <div className="media e-media-card p-3">
                      <div className="media-body">
                        <h6 className="e-home-media-head mt-2">Special Discounts & Offers</h6>
                      </div>
                      <img src={discount} alt="image" className="mt-5 e-why-trade-card-img" />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-lg-6 col-md-6 ">
                    <div className="e-algo-card media e-media-card  p-3">
                      <div className="media-body">
                        <h6 className="e-home-media-head mt-2">Free Trials</h6>
                      </div>
                      <img src={algobased} alt="image" className="mt-5 e-why-trade-card-img" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-4">
                    <div className="media e-media-card p-2">
                      <div className="media-body e-blue-card">
                        <h6 className="e-home-media-head p-2">Customized Trading</h6>
                      </div>
                      <img src={custometrade} alt="image" className="e-why-trade-card-img-blue" />
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </section>

        <section className="e-home-explore-prdt-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 text-left">
                {/* <hr className="e-form-rule" />
                <h5 className="e-home-head mt-3">Explore Products</h5> */}
              </div>
              <div className="col-lg-6 col-md-6 text-lg-right text-md-right mt-3">
                {
                  localStorage.getItem("tradestore_token") ?
                    <Link to="./marketplace" className="mr-5 pr-2 e-visit-market">Visit Marketplace<img src={visitArrow} alt="arrow" className="e-visit-market-arrow" /></Link>
                    :
                    <Link onClick={showLogin} className="mr-5 pr-2 e-visit-market">Visit Marketplace<img src={visitArrow} alt="arrow" className="e-visit-market-arrow" /></Link>

                }

              </div>
            </div>
            <div className="row mt-3">
              {
                this.state.products.map((item, index) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-sm-10">
                      <ProductCard data={item} />
                    </div>
                  );
                })
              }
            </div>
          </div>
        </section>

        <section className="e-home-playstore-sec">
          <div className="container">
            <div className="col-lg-12">
              <Playstore />
            </div>
            <div className="col-lg-12 mt-5 pt-5">
              {/* <a href="https://www1.nseindia.com/content/circulars/COMP53555.pdf" target="_blank"><b>Note: </b>As per the SEBI circular dated 02 September 2022 & NSE Circular dated 06 September 2022, Alice Blue is not directly or indirectly associated with any Algo trading platforms that claim past or expected future returns/performance on strategies.</a> */}
              <p className='e-note-text-p'><b>Note: </b>As per the <a href="https://www.sebi.gov.in/legal/circulars/sep-2022/performance-return-claimed-by-unregulated-platforms-offering-algorithmic-strategies-for-trading_62628.html" target='_blank'>SEBI circular dated 02 September 2022</a> & <a href="https://www1.nseindia.com/content/circulars/COMP53555.pdf" target='_blank'>NSE Circular dated 06 September 2022</a>, Alice Blue is not directly or indirectly associated with any Algo trading platforms that claim past or expected future returns/performance on strategies.</p>
            </div>
          </div>
        </section>
        {/*<section className="e-home-card-sec">
					<div className="container pt-5">
						<div className="row">
							<div className="col-lg-4 col-md-6 ">
								<div className="card e-home-card p-4 text-center">
									<img src={RevenueBro} alt="card image" className="e-card-img"/>
									<h6 className="e-home-media-head mt-5 ">TRADERS</h6>
									<p className="mt-2 e-card-p"><span className="e-dot">.</span> Maximum Limits</p>
									<p className="e-card-p"><span className="e-dot">.</span> Trading Apps</p>
									<p className=" e-card-p"><span className="e-dot">.</span> Trade From Charts</p>
									<div className="mt-4 text-lg-right">
										<a href='marketplace' className="e-card-explore">Marketplace <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="card e-home-card p-4 text-center">
									<img src={SavingsBro} alt="card image" className="e-card-img"/>
									<h6 className="e-home-media-head mt-5">INVESTORS</h6>
									<p className="mt-2  e-card-p"><span className="e-dot">.</span> Free Equity Delivery</p>
									<p className=" e-card-p"><span className="e-dot">.</span> Investment In Direct Funds</p>
									<p className=" e-card-p"><span className="e-dot">.</span> Online IPO Facilityy</p>
									<div className="mt-4 text-lg-right">
										<a href='marketplace'  className="e-card-explore">Marketplace <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3 ">
								<div className="card e-home-card p-4 mt-lg-0 mt-md-4 text-center">
									<img src={DarkBro} alt="card image" className="e-card-img"/>
									<h6 className="e-home-media-head mt-5 text-center">STRATEGISTS</h6>
									<p className="mt-2  e-card-p"><span className="e-dot">.</span> Custom Algo Strategy</p>
									<p className="e-card-p"><span className="e-dot">.</span> Free API</p>
									<p className="e-card-p"><span className="e-dot">.</span> Multiple Algo Trading Products</p>
									<div className="mt-4 text-lg-right">
										<a href='marketplace'  className="e-card-explore">Marketplace <img src={cardarrow} alt="arrow" className="e-card-arrow"/></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>*/}

        <section className="e-join-ab-sec">
          <div className="container-fluid">
            <JoinAliceblue />
          </div>

          <VideoModal modalId="homevideo">
            <button className="e-videomodal-close" data-dismiss="modal" aria-hidden="true" onClick={this.pauseVideo}><img className="e-toggle" src={closebtn} alt="button" /></button>
            <ReactPlayer id="player" url="https://d1ippcqariu474.cloudfront.net/static/tradestore-video.mp4" playing={this.state.play && this.state.isModalOpen} controls={true} className="e-react-player" width="100%" />
          </VideoModal>

        </section>
        {
          this.state.notification && this.state.notification.id && !JSON.parse(localStorage.getItem('popup_close')) ?
            <PopUp data={this.state.notification} close={() => { this.closePopUp() }} />
            :
            null
        }
        <ConfirmAgreement
          home={true}
        />
      </React.Fragment>
    )
  }
}
