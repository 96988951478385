import React from 'react';
import $ from 'jquery';
// import components
import Links from '../../components/navigation/Links';
import Input from '../../components/formComponents/input';
import Toast from '../../components/popup/Toast';
import Login from '../../components/loginSignup/Login';
// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

// import assets
import HeaderImg from '../../assets/images/header/headerimage.png';
import facebook from '../../assets/images/footerIcons/facebook.svg';
import twitter from '../../assets/images/footerIcons/twitter.svg';
import youtube from '../../assets/images/footerIcons/youtube.svg';
import FooterIcon from '../../assets/images/footerIcons/fi.svg';
import Footericon from '../../assets/images/footerIcons/footericon.svg';
import Footericon1 from '../../assets/images/footerIcons/footericons1.svg';



export default class footer extends React.Component{
	constructor(props){
		super(props);
		this.state={
			mailError: "",
			subMail: "",
		}
	}


	validate(){
		let mail_check =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.subMail);
		let mailError= "";
		if (mail_check == false) {
			mailError = 'Invalid email';
		}
		if(mailError){
			this.setState({mailError});
			return false;
		}
		return true;
	}

	 showLogin() {
		var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  		$("body").append(overlaylogin);
		$('.e-login').addClass('e-login-fly'); 
	}
	submit =(e) =>{
		e.preventDefault();
		const isValid = this.validate();
		if (isValid){
		var url = devconfig.v3+"/newsletter/subscribe";
    var data = JSON.stringify({
      "email" : this.state.subMail,
    })
     APIPost(url, data).then(function(response){
       if(response.status === "ok"){
        this.setState({toast: "Thank you for subscribing. You will receive new product releases & offers in your email", toastType: "success",  subMail: ""});
        setTimeout( function() { this.setState({ toast: null, subMail: null}); }.bind(this), 4000);
      }
      else{
      	 this.setState({toast: response.message, toastType: "fail"});
        setTimeout( function() { this.setState({ toast: null, subMail: null}); }.bind(this), 4000);
      }
      
    }.bind(this))
		}
	}


	

	render(){
		return(
		<div className="conatiner-fluid ">
			<div className="row e-footer-newsletter-sec e-fooet-sec">
				<div className="col-lg-3 col-md-3 col-sm-12">
					<img src={HeaderImg} alt="Company Logo"/>
					<p className="mt-4 e-footer-companyinfo"><a href="tel:080-45412050">080-45412050</a>, <a href="tel:080-45366890">080-45366890</a><br/>  <a href="mailto:tradestore@aliceblueindia.com">tradestore@aliceblueindia.com</a></p>
					
					<p className="mt-5">
						<a className="mr-3 e-ftricon-link" href="https://www.facebook.com/aliceblue.commodities" target="blank"><img src={facebook} alt="icon" className="e-footer-icon"/></a>  
						<a className="mr-3 e-ftricon-link" href="https://twitter.com/aliceblue_india?lang=en" target="blank"><img src={twitter} alt="icon" className="e-footer-icon"/></a>
						<a className="mr-3 e-ftricon-link" href="https://www.youtube.com/channel/UCrAM1_NV3j5_l8VoukiXv2g?sub_confirmation=1" target="blank"><img src={youtube} alt="icon" className="e-footer-icon"/></a> 
						<a className="mr-3" href="https://www.aliceblueonline.com/digipromo/" target="blank"><img src={Footericon1} alt="icon" className="e-footer-icon"/></a>
						<a className="" href="https://aliceblueonline.com/market-talk" target="blank"> <img src={FooterIcon} alt="icon" className="e-footer-icon"/></a>
					</p>
					<p className="d-block e-footer-companyinfo">@{new Date().getFullYear()} Aliceblue. All right reserved<br/>
					Powered By <a className="e-evolab-link" href="https://evoqins.com?ref=ts" target="blank">Evoqins</a></p>
				</div>
				<div className="col-lg-2 col-md-2 col-6">
					<h6>Trade store</h6>
					<p className="mt-4"><a className="e-footer-bold-paragraph e-footer-link" href="marketplace">Marketplace</a></p>
					{/* {<p><a className="e-footer-bold-paragraph e-footer-link" href="resources">Resource</a></p>} */}
					<p><a className="e-footer-bold-paragraph e-footer-link" href="contact">Contact</a></p>
					<p><a className="e-footer-bold-paragraph e-footer-link" onClick={this.showLogin}>Login</a></p>
				</div>
				<div className="col-lg-2 col-md-2 col-6">
					<h6>Aliceblue</h6>
					<p className="mt-4"><a href="https://aliceblueonline.com/" target="#_blank" className="e-footer-bold-paragraph e-footer-link">Home</a></p>
					<p><a href="https://aliceblueonline.com/margin-calculator/" target="#_blank" className="e-footer-bold-paragraph e-footer-link">Margin Calculator</a></p>
					<p><a href="https://aliceblueonline.com/brokerage-calculator/" target="#_blank" className="e-footer-bold-paragraph e-footer-link">Brokerage Calculator</a></p>
					<p><a href="https://aliceblueonline.com/support/" target="#_blank" className="e-footer-bold-paragraph e-footer-link">FAQ</a></p>
					<p><a href="https://aliceblueonline.com/legal-documentation/disclaimer/" target="#_blank" className="e-footer-bold-paragraph e-footer-link">Disclaimer</a></p>
					{/* <p><a href="https://aliceblueonline.com/market-talk/" target="#_blank" className="e-footer-bold-paragraph e-footer-link">Blog</a></p> */}
				</div>
				<div className="col-lg-5 col-md-5 col-sm-12 col-12  e-ftr-subsription-sec">
					<h6>Subscribe to our Newsletter</h6>
					<p className="e-footer-bold-paragraph">Subscribe to our newsletter & stay informed of <br/> new product releases & offers</p>
					<form >
						<input type="text" placeholder="Email Id" className="e-footer-email-input" value={this.state.subMail}   onChange={(e) => this.setState({subMail: e.target.value, mailError: ""})}/>
						
						<button className="e-footer-subs-btn" disabled={!this.state.subMail} onClick={this.submit}>Subscribe</button>
						<div className="text-left" style={{color: "red"}}>{this.state.mailError}</div>
					</form>
				</div>
			</div>
			<div className="row  e-footer-address-sec e-fooet-sec">
				<div className="col-lg-6 col-md-6 col-sm-6">
					<p>Corporate Office: No. 153/2, 3rd Floor, M.R.B.Arcade, Bagalur Main Road, Dwaraka Nagar, Yelahanka, Bengaluru-560 063, Karnataka. </p>
					<p className="mt-3">Alice Blue Financial Services (P) Ltd: NSE EQ | NSE FO | NSE CDS-90112 SEBI REG: INZ000156038</p>
					<p className="mt-3">Alice Blue Financial Services (P) Ltd : CDSL ID 12085300 DP SEBI REG : IN-DP-364-2018</p>
					<p> <a href="https://www.mcxindia.com/" target="#_blank"><span className="mr-3 e-footer-blueline">MCX</span></a> <a href="https://www.msei.in/Index" target="#_blank"><span className="mr-3 e-footer-blueline">MCX-SX</span></a> <a href="https://www.nseindia.com/" target="#_blank"><span className="mr-3 e-footer-blueline">NSE</span></a> <a href="https://www.cdslindia.com/" target="#_blank"><span className="mr-3 e-footer-blueline">CDSL</span></a> <a href="https://www.bseindia.com/" target="#_blank"><span className="mr-3 e-footer-blueline">BSE</span></a> <a href="https://www.sebi.gov.in/" target="#_blank"><span className="e-footer-blueline">SEBI</span></a></p>
				</div>
				<div className="col-lg-6 col-md-6 col-sm-6">
					<p>Registered Office: Old No.56/2, New No.58, LIC Nagar, Vinayagar Kovil Street, Erode -8 Tamil Nadu- 638002.</p>
					<p className="mt-3">Alice Blue Financial Services (P) Ltd: BSE EQ | BSE FO | BSE CD-6670 SEBI REG: INZ000156038</p>
					<p className="mt-3">Alice Blue Financial Services (P) Ltd: MCX-56710 SEBI REG : INZ000156038</p>
				</div>
				<div className="col-lg-12 col-md-12 col-sm-12">
					<p><img src={Footericon} alt="icon" className="mr-2" /><span className="mr-3 e-footer-grnline">Attention Inverstors</span> <marquee className="e-marque"> "Prevent unauthorized transations in your account update your mobile nuumber/email id with your Stock Broker. Receive information of your transations directly from Exc</marquee></p>
				</div>
			</div>
			<div className="row  e-footer-declaimer-sec e-fooet-sec">
				<div className="col-lg-12">
					<p>Procedure to file a complaint on <a href=" https://scores.gov.in/scores/Welcome.html" target="#_blank"><span className="e-footer-blueline">SEBI SCORES</span></a> : Register on SCORES portal. Mandatory details for details for filling complaints on SCORES: Name, PAN, Address, Mobile Number, E-mail ID.Benefits: Effective Communication, Speedy redressal of the grievances </p>
					<p className="mt-3">Investment in securities market are subject to market risks, read all the related document carefully before investing.</p>
					<p className="mt-3">For queries regarding account opening or activation, emial to <a href="mailto:accountactivation@aliceblueindia.com" target="blank"><span  className="e-footer-blueline">accountactivation@aliceblueindia.com</span></a> and for fund udpdates, email to <a href="mailto:funds@aliceblueindia.com" target="blank"><span  className="e-footer-blueline">funds@aliceblueindia.com</span></a></p>
					<p className="mt-3"><span className="font-weight-normal">Disclaimer:</span>  Alice Blue Financial Services Pvt Ltd is a financial services intermediary and is engaged as Stock Broker since 2007. Alice Blue and/or any of its affiliates do not provide any tips, advisory, solicitation, opinions or portfolio management services regarding buying, selling and trading of any securities, directly or indirectly, in any manner. Information displayed or laid out by Alice Blue, including social media, are purely for either educational, informational, or discussion purposes and should not be construed as direct or indirect recommendation to invest, trade or speculate in the markets. Alice Blue would welcome and appreciate immediate notification or notice, if any person would come across any such tips, advisory, solicitation, opinions or related services regarding buying, selling and trading of any securities, directly or indirectly, in any manner from any person or platform which is believed to be or likely to be believed as Alice Blue. Please contact compliance@aliceblueindia.com for any of your queries with regard to the same. In no event shall Alice Blue be liable for any investments, trades or speculative activities performed by any person based on any such information or content and all such activities shall be solely at their own risk.

					Customers need to check products & features before investing since the contours of the product rates may change from time to time. Alice Blue Financial Services is not liable for any loss or damage of any kind arising out of investments in these products.

					Investments in Equity, Currency, Futures & Options, and Commodities are subject to market risk.

					Clients should read the Risk Disclosure Document issued by SEBI & relevant exchanges & the T&C on www.aliceblueonline.com before investing.</p>
				</div>
			</div>
			 {
			 	this.state.toast ?
			 	<Toast data={this.state.toast} type={this.state.toastType}/>
			 	:
			 	null
			 }
		</div>
	)
	}
	
}
