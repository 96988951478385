import React from 'react';
import playstoreadd from '../../assets/images/AppstoreBanner/playstoreadd.png';
import playstorebg1 from '../../assets/images/AppstoreBanner/playstorebg1.png';
import playstorebg2 from '../../assets/images/AppstoreBanner/playstorebg2.png';
import playstorebg3 from '../../assets/images/AppstoreBanner/playstorebg3.png';
import playstorebg4 from '../../assets/images/AppstoreBanner/playstorebg4.png';
import playstorebg5 from '../../assets/images/AppstoreBanner/playstorebg5.png';


function playstoreBanner(props){
	function redirect(){
		window.open('https://play.google.com/store/apps/details?id=com.tradestore&hl=en_IN&gl=US', '_blank');
	}

	return(

		<div className="row e-playstore-banner">
		<img src={playstorebg4} alt="background pattern" className="e-playstorebannerbg-red"/>
			<div className="col-lg-5 col-md-10 ">

				<div className="col-lg-12 col-md-10 ">
					<h2 className="mt-2 e-playstorebanner-head">Now customize your trading on the go.</h2>
					<p className=" mt-4  e-playstorebanner-para">Download Trade Store app and start your journey with us! Available on android.</p>
				</div>


				<div className="col-lg-12">
					<button className="mr-3    e-playstorelink-btn" onClick={redirect}></button>
					{/* <button className="e-appstorelink-btn"></button> */}
				</div>
			</div>
				<img src={playstorebg3} alt="background pattern" className="e-playstorebannerbg-bluelg"/>
				<img src={playstorebg2} alt="background pattern" className="e-playstorebannerbg-blue"/>
				<img src={playstorebg5} alt="background pattern" className="e-playstorebannerbg-orng"/>
				<img src={playstorebg1} alt="background pattern" className="e-playstorebannerbg-green"/>
				<img src={playstoreadd} alt="trade store add" className="e-playstorebanner-img"/>
		</div>

	)
}

export default playstoreBanner;