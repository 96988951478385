import React from 'react';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config';

// import components
import PrimaryBlueButton from '../buttons/primaryBlueButton';
import PrimaryOutlineButton from '../buttons/primaryOutlineButton';

import Star from '../../assets/images/rating/four.svg';
import AvgReview from '../starRating/avgReview';

function ProductCard(props) {
	
	function _redirectToExternalURL () {
		window.open(props.data.external_link, '_blank');
	}

	var tempBgColor = "linear-gradient( to top, " + props.data.gradient_1 + " , " + props.data.gradient_2 + ")";

	return (
		<div className={"card e-marketDetails-card border-0 " + props.className}>
			<div className="e-card-head e-sensi-hed p-3" style={{ backgroundImage: tempBgColor }}>
				<div className="d-flex justify-content-between">
					<img className="card-logo" src={props.data.logo} alt="Card image cap" />
					<img className="card-illustration" src={props.data.illustration} alt="Card image cap" />
				</div>

				<div className="e-card-rating">
					{/*<span className="e-fill-star"></span>
          <span className="e-half-fill-star"></span>
          <span className="e-no-fill-star"></span>*/}
					{
						props.data.review_count ?
							<React.Fragment>
								<AvgReview rating={props.data.rating} type={1} /> <span>({props.data.review_count} Reviews)</span>
							</React.Fragment>
							:
							null

					}

				</div>
				<span className="e-card-overlay"></span>
			</div>
			<div className="card-body">
				<h5 className="e-mrktplc-card-title">{props.data.title}</h5>
				<p className="e-mrktplc-card-text">{props.data.description}</p>
				{
					props.data.filters.map((item, index) => {
						return (
							<span className="e-card-type-tag" key={index}>{item.name}</span>
						);
					})
				}
				

				<div className="row e-card-btn-wrap mt-5">
					<div className="col-12 pr-lg-2">
						<PrimaryBlueButton name="Click to know more" handleClick={_redirectToExternalURL} />
					</div>
				</div>
			</div>
		</div>

	)

}
export default ProductCard;